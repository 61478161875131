import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
setTimeout(() => {
  if (window.location.href.includes("dev")) {
    window.location.href = "https://dev.app.equips.com";
  } else {
    window.location.href = "https://app.equips.com";
  }
}, 3000);
