import React from "react";

function App() {
  return (
    <div>
      <header>cache buster</header>
    </div>
  );
}

export default App;
